import React from 'react'
import './Portfolio.css'

const Portfolio = () => {
	return (
		<div>
			<h2>UNDER DEVELOPMENT... COMING SOON!</h2>
		</div>
	)
}

export default Portfolio
