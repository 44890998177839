import React from 'react';
import './Footer.css';

const Footer = () => {
	return (
		<footer >
			<small className="footerText">Designed and developed by Matt Dwyer</small>
		</footer>
	);
};

export default Footer