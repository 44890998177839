import React from 'react'
import './StaticBackground.css'

function StaticBackground() {
	return (
		<div className="StaticBackground">
		</div>
	)
}

export default StaticBackground
